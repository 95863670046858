<template>
  <d-container fluid>
    <pageTitle title="Modellen" :back="true"/>
    <d-row>
      <vCard :col="8" header="Modellen" :fluid="true">
        <vTable :fields="fields" :actions="actions" :data="data"/>
      </vCard>
      <vCard :col="4" header="Merken">
        <addModel :make="{id: $route.params.make_id}" @submit="eventAddModel" />
      </vCard>
    </d-row>
  </d-container>
</template>

<script>

export default {
  name: 'Overzicht',
  title: 'Merken en Modellen',
  components: {
    addModel: () => import('@/components/forms/model/addModel')
  },
  data() {
    return {
      actions: [
        {
          icon: '<i class="material-icons">visibility</i>',
          fn: (model) => {
            this.$router.push({name: 'dashboard/inkoop/makeAndModel/model', params: {make_id: this.$route.params.make_id, model_id: model.id}})
          }
        }
      ],
      data: null,
      fields: [
        {
          name: 'name',
          title: 'Merken',
          sortField: 'name'
        },
        'actions'
      ],
    }
  },
  created() {
    this.getModels()
  },
  mounted() {
  },
  methods: {
    async getModels() {
      try {
        let {data} = await this.$api.model.getByMake(this.$route.params.make_id)
        this.data = data
      } catch (e) {
      }
    },
    eventAddModel(value) {
      this.data.push(value)
    }
  }
}
</script>
